<template>
  <div>
    <div style="margin-bottom: 10px;display: flex;justify-content: space-between">
      <div>
        <el-date-picker
            v-model="value2"
            type="date"
            placeholder="选择日期"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            style="margin-right: 50px"
            size="small"
            @change="changedate"
        >
        </el-date-picker>
        <el-button type="primary" size="small" @click="seleque">查询</el-button>
      </div>
      <div>
        <el-button type="danger" style="float: right;margin-right: 100px" size="small"><a style="text-decoration:none;color: white" :href="url">导出</a></el-button>
      </div>
      <div>
        <el-button type="warning" style="float: right;margin-right: 100px" size="small" @click="leadout">导出洞口汇总表
        </el-button>
      </div>
      <div v-if="GET_USERID === '828gXLp1'">
        <el-button type="warning" style="float: right;margin-right: 100px" size="small" @click="test">存入缓存</el-button>
      </div>
      <div v-if="GET_USERID === '828gXLp1'">
        <el-button type="success" style="float: right;margin-right: 100px" size="small"><a style="text-decoration:none;color: white" :href="urlbz">导出日报备注</a></el-button>
      </div>
    </div>
    <div v-if="tx" style="width: 100%;margin-top: 50px;margin-bottom: 50px">
      <div style="margin: 0 auto;width: 450px">
        <el-card class="box-card">
          <el-result
              icon="success"
              :title="datelook"
          >
            <template #extra>
              <p style="font-size: 26px;font-weight: bolder;color: red">{{ addnum }}个洞口</p>
              <el-button type="primary" plain @click="tx = false">查看详细</el-button>
            </template>
          </el-result>
        </el-card>
      </div>
    </div>
    <div v-if="!tx">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="small"
          highlight-hover-row
          :span-method="mergeRowMethod"
          :cell-style="cellStyle"
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="operpotion" title="工点" width="180"></vxe-column>
        <vxe-column field="sum" title="循环时间" width="85"></vxe-column>
        <vxe-column field="milesum" title="循环进尺(榀/m)" width="85"></vxe-column>
        <vxe-column field="addup" title="累计进尺" width="70"></vxe-column>
        <vxe-colgroup title="工序用时">
          <vxe-column field="gxflkw" title="开挖" width="70"></vxe-column>
          <vxe-column field="gxflcz" title="出渣" width="70"></vxe-column>
          <vxe-column field="gxfllj" title="立架" width="70"></vxe-column>
          <vxe-column field="gxflpm" title="喷锚" width="70"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="间隔时间">
          <vxe-column field="allsum" title="间隔时间" width="70"></vxe-column>
          <vxe-column field="spacetime" title="原因" width="70" show-overflow="tooltip"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="投入要素">
          <vxe-column field="peonumkw" title="开挖" width="130" show-overflow="tooltip"></vxe-column>
          <vxe-column field="peonumcz" title="出渣" width="130" show-overflow="tooltip"></vxe-column>
          <vxe-column field="peonumlj" title="立架" width="130" show-overflow="tooltip"></vxe-column>
          <vxe-column field="peonumpm" title="喷锚" width="120" show-overflow="tooltip"></vxe-column>
        </vxe-colgroup>
        <vxe-column field="beizhu" title="备注" width="220" show-overflow="tooltip"></vxe-column>
        <vxe-column title="操作" width="100" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="getSelectionEvent(row)">查看明细</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

<!--    明细表-->
    <div>
      <vxe-modal v-model="mx" width="1000" height="650" :title="popname" show-zoom resize remember>
        <template #default>
          <div style="margin-bottom: 10px">
            <el-tag style="margin-right: 5px">填报人</el-tag>
            <el-tag>{{ username }}</el-tag>
          </div>
          <vxe-table
              show-overflow
              auto-resize
              height="350"
              :data="tableMx.collMxes">
            <vxe-column field="sgdate" title="施工日期"></vxe-column>
            <vxe-column field="gxname" title="工序"></vxe-column>
            <vxe-column field="starttime" title="开始"></vxe-column>
            <vxe-column field="endtime" title="结束"></vxe-column>
            <vxe-column field="gxtime" title="工序时长(min)"></vxe-column>
            <vxe-column field="jgtime" title="间隔时间(min)"></vxe-column>
            <vxe-column field="except" title="情况说明"></vxe-column>
            <vxe-column field="pro" title="人员及设备投入"></vxe-column>
          </vxe-table>
          <div style="margin-top: 10px">
            <el-tag style="margin-right: 10px">循环进尺</el-tag>
            <el-tag style="margin-right: 80px">{{ mile }} m/ {{ pin }} 榀</el-tag>
            <el-tag size="medium" style="margin-right: 10px">月累</el-tag>
            <el-tag style="margin-right: 80px">{{ moutnmile }}m</el-tag>
            <el-tag size="medium" style="margin-right: 10px">累计进尺</el-tag>
            <el-tag style="margin-right: 80px">{{ milesum }}m</el-tag>
          </div>
          <div style="margin-top: 30px">
            <el-tag style="margin-right: 10px">掌子面里程</el-tag>
            <el-tag>{{ milexx }}</el-tag>
          </div>
          <div style="margin-top: 30px">
            <el-tag style="margin-right: 10px">本次开挖支护循环总时间</el-tag>
            <el-tag style="margin-right: 80px">{{ timesum }}</el-tag>
            <el-tag style="margin-right: 10px">本次开挖支护循环间隔总时间(min)</el-tag>
            <el-tag>{{ jgsum }}</el-tag>
          </div>
          <div v-for="(img, i) in imgs" style="display: flex;margin-top: 20px">
            <div>
              <img style="width: 150px;height: 150px" :src="img" alt="#" @click="getbig(img)"/>
            </div>
          </div>
          <div style="text-align: center;margin-top: 20px">
            <vxe-button type="submit" status="warning" @click="bcakbb">删除</vxe-button>
            <vxe-button type="reset" @click="mx = false">返回</vxe-button>
          </div>
        </template>
      </vxe-modal>
    </div>
    <div>
      <el-dialog :fullscreen="true" title="图片详情" v-model = "dialogVisibleimg" :before-close="handleClose">
        <div v-for="img in imgsbig">
          <img :src="img"/>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleimg = false">确 定</el-button>
      </span>
      </el-dialog>
    </div>
<!--    按时间范围-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          title="导出各洞口"
          width="50%">
        <el-date-picker
            v-model="dates"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            @change="geturl"
        >
        </el-date-picker>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取消</el-button>
            <el-button size="small" type="primary"><a
                style="text-decoration:none;color: white" :href="url2">导出每日汇总表</a></el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {minTime, setNowDate} from '@/utils/time';
import { ElMessage } from 'element-plus';
import {Decrypt} from "@/utils/secret";
import {mapGetters} from "vuex";
export default {
  inject: ['reload'],
  name: "selsdgx",
  data(){
    return{
      tableData: [],
      allAlign: 'center',
      value2: '',
      mx: false,
      tableColumn: [],
      url: '',
      tableMx: [],
      popname: '',
      moutnmile: '',
      milesum: '',
      mile: '',
      milexx: '',
      jgsum: '',
      timesum: '',
      pin: '',
      uuid: '',
      imgs: [],
      dialogVisibleimg: false,
      imgsbig: [],
      username: '',
      tx: true,
      addnum: '',
      datelook: '',
      dialogVisible: false,
      dates: '',
      url2: '',
      urlbz: ''
    }
  },
  created() {
    this.value2 = setNowDate(new Date());
    let date = setNowDate(new Date());
    this.gettable(date);
    this.url = 'https://www.gjegm.ltd/czsczq/other/leadout?date=' + setNowDate(new Date()) + '&bdst=1&tj=1';
    this.urlbz = 'https://www.gjegm.ltd/czsczq/collectmain/leadbz?date=' + setNowDate(new Date()) + '&bdst=1';
    this.datelook = this.value2 + '已填报';
    //this.url = 'http://localhost:8082/other/leadout?date=' + setNowDate(new Date());
  },
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USERID'])

  },
  methods:{
    handleClose(done) {
      this.dialogVisibleimg = false
    },
    getbig(img){
      this.imgsbig = [];
      this.imgsbig.push(img)
      console.log(this.imgsbig)
      this.dialogVisibleimg = true;
    },
    cellStyle ({ row, rowIndex, column }) {
      if (column.property === 'addup') {
        if (row.addup === 0) {
          return {
            backgroundColor: 'red',
            color: '#ffffff'
          }
        }
      }
      if (column.property === 'start') {
        if (row.start === '/') {
          return {
            backgroundColor: 'green',
            color: '#ffffff'
          }
        }
      }
    },
    gettable(val){
      this.axios.post("/collectmain/seldate", (response) => {
        this.tableData = response.obj;
        console.log(response.obj)
        if (this.tableData.length === 0){
          this.addnum = 0;
        }else {
          this.addnum = 1;
          for (let i = 0; i < this.tableData.length - 1; i++) {
            if (this.tableData[i].operpotion !== this.tableData[i + 1].operpotion) {
              this.addnum = (this.addnum + 1) * 1
            }
          }
        }
      },{
        date: val,
        bdst: 1,
        tj: 1
      })
    },
    seleque(){
      this.datelook = this.value2 + '已填报';
      this.gettable(this.value2);
    },
    mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
      const fields = ['operpotion']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return {rowspan: 0, colspan: 0}
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return {rowspan: countRowspan, colspan: 1}
          }
        }
      }
    },
    getSelectionEvent (row) {
      this.uuid = row.id;
      this.mile = row.milesum;
      this.popname = row.operpotion;
      this.moutnmile = row.mothsum;
      this.milesum = row.addup;
      this.timesum = row.sum;
      this.jgsum = row.allsum;
      this.mx = true;
      this.axios.post("/collectmain/getmx", (response) => {
        this.tableMx = response.obj;
        this.milexx = response.obj.collectmain.mile;
        this.pin = response.obj.collectmain.pinshu;
        this.username = response.obj.user;
        for (let i = 0; i < this.tableMx.collMxes.length; i++){
          if (this.tableMx.collMxes[i].pro === 'undefined'){
            this.tableMx.collMxes[i].pro = ' '
          }
          if (this.tableMx.collMxes[i].except === 'undefined'){
            this.tableMx.collMxes[i].except = ' '
          }
        }
        this.axios.post("other/selimgs", (responseimg) => {
          this.imgs = responseimg.obj
        },{
          id: row.id
        })
      },{
        id: row.id
      })
    },
    bcakbb(){
      const that = this;
      that.axios.post("/collectmain/backsgdx", (response) => {
        if (response.obj) {
          that.reload();
          that.mx = false;
          ElMessage.success({
            message: '删除成功',
            type: 'success',
          });
        }
      }, {
        id: that.uuid
      })
    },
    changedate(){
      //this.url = 'https://www.gjegm.ltd/czsczq/other/leadout?date=' + this.value2;
      this.url = 'https://www.gjegm.ltd/czsczq/other/leadout?date=' + this.value2 + '&bdst=1&tj=1';
      this.urlbz = 'https://www.gjegm.ltd/czsczq/collectmain/leadbz?date=' + this.value2 + '&bdst=1';
    },
    leadout() {
      this.dialogVisible = true;
    },
    geturl(){
      //this.url2 = 'https://www.gjegm.ltd/czsczq/czbeton/leadoutsan?times=' + this.dates
      this.url2 = 'https://www.gjegm.ltd/czsczq/collectmain/leadoutdays?times=' + this.dates
    },
    test(){
      this.axios.get('/collectmain/getredis', (response) => {
        console.log(response)
        if (response.code === 200){
          ElMessage.success({
            message: '缓存成功',
            type: 'success',
          });
        }
      },{
        date: this.value2
      })
    }
  }
}
</script>

<style scoped>
</style>
