<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">查询报表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div id="sel_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="查询隧道工效" name="a" ><Selsdgx></Selsdgx></el-tab-pane>
        <el-tab-pane label="查询混凝土初支" name="b"><Selfirsrshor></Selfirsrshor></el-tab-pane>
        <el-tab-pane label="沉降观测" name="c"><Selsink></Selsink></el-tab-pane>
        <el-tab-pane label="二衬报表" name="d"><Selseclin></Selseclin></el-tab-pane>
        <el-tab-pane label="仰拱报表" name="3"><Selinvert></Selinvert></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Selsdgx from "@/views/procoll/sel/selsdgx"
import Header from "@/views/header";
import Selfirsrshor from "@/views/procoll/sel/selfirstshor";
import Selsink from "@/views/procoll/sel/selsink";
import Selseclin from "@/views/procoll/sel/selseclin";
import Selinvert from "@/views/procoll/sel/selinvert";
export default {
  name: "selforms",
  components: {Selsdgx, Header, Selfirsrshor, Selsink, Selseclin, Selinvert},
  data(){
    return{
      activeName: 'a'
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
    if (sessionStorage.getItem('selformsnames')){
      this.activeName = sessionStorage.getItem('selformsnames');
    }
  },
  methods:{
    handleClick(tab, event){
      console.log(tab)
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('selformsnames', tab.props.name)
    },
    reall(){
      sessionStorage.removeItem('selformsnames');
    },
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
#sel_div2{
  margin: 0 auto;
  background-color: white;
  padding-top: 30px;
  width: 95%;
  min-height: 500px;
}
</style>
