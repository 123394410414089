<!--查询报表，初支-->
<template>
  <div>
    <div style="margin-bottom: 10px;display: flex;justify-content: space-between">
      <div>
        <el-date-picker
            v-model="datesel"
            type="date"
            placeholder="选择日期"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            style="margin-right: 50px"
            size="small"
            @change="changeurl"
        >
        </el-date-picker>
        <el-button type="primary" size="small" @click="selbeton">查询</el-button>
      </div>
      <div style="margin-left: 10px" v-if="GET_BDST === '1'">
        <el-button type="info" size="small" @click="test">按洞口导出</el-button>
      </div>
      <div>
        <el-button type="danger" size="small"><a
            style="text-decoration:none;color: white" :href="url">导出每日汇总表</a></el-button>
      </div>
      <div>
        <el-button type="warning" style="float: right;margin-right: 100px" size="small" @click="leadout">导出洞口汇总表
        </el-button>
      </div>
    </div>
    <div>
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column field="bdstname" title="工区" width="100"></vxe-column>
        <vxe-column field="opername" title="工点" width="180"></vxe-column>
        <vxe-column field="mile" title="里程" width="150"></vxe-column>
        <vxe-column field="m" title="部位" width="80">
          <template #default="{ row }">
            <p v-if="row.orderflag === 0">未喷</p>
            <p v-if="row.orderflag === 1">全断面</p>
            <p v-if="row.orderflag === 2">上台阶</p>
            <p v-if="row.orderflag === 3">中台阶</p>
            <p v-if="row.orderflag === 4">下台阶</p>
            <p v-if="row.orderflag === 5">仰供支护</p>
          </template>
        </vxe-column>
        <vxe-column field="oppmile" title="相对进尺长度(m)" width="70"></vxe-column>
        <vxe-column field="planps" title="设计喷栓量" width="70"></vxe-column>
        <vxe-column field="realps" title="实际喷栓量" width="80"></vxe-column>
        <vxe-column field="consu" title="应耗量" width="80"></vxe-column>
        <vxe-column field="morenum" title="超耗" width="80"></vxe-column>
        <vxe-column field="more" title="喷栓超耗率(%)" width="80"></vxe-column>
        <vxe-column field="maxnum" title="最大线性超挖" width="80"></vxe-column>
        <vxe-column field="meanum" title="平均线性超挖" width="80"></vxe-column>
        <vxe-column field="beizhu" title="备注" width="280"></vxe-column>
        <vxe-column field="stake" title="是否立架" width="100"></vxe-column>
        <vxe-column title="操作" width="100" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="getSelectionEvent(row)">查看明细</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!--    明细弹窗-->
    <div>
      <vxe-modal v-model="mx" width="1500" height="650" :title="opername" show-zoom resize remember>
        <template #default>
          <vxe-table
              show-overflow
              auto-resize
              height="350"
              :span-method="mergeRowMethod"
              :align="allAlign"
              :data="tableMx">
            <vxe-column field="mile" width="200" title="里程"></vxe-column>
            <vxe-column field="m" title="部位" width="80">
              <template #default="{ row }">
                <p v-if="row.orderflag === 1">全断面</p>
                <p v-if="row.orderflag === 2">上台阶</p>
                <p v-if="row.orderflag === 3">中台阶</p>
                <p v-if="row.orderflag === 4">下台阶</p>
                <p v-if="row.orderflag === 5">仰供支护</p>
              </template>
            </vxe-column>
            <vxe-column field="oppmile" title="相对进尺长度"></vxe-column>
            <vxe-column field="planum" title="设计量(m³)"></vxe-column>
            <vxe-column field="realnum" title="实际量(m³)"></vxe-column>
            <vxe-column field="consu" title="应耗量(m³)"></vxe-column>
            <vxe-column field="morenum" title="超耗(m³)"></vxe-column>
            <vxe-column field="more" title="超耗率(%)"></vxe-column>
            <vxe-column field="maxnum" title="最大线性超挖(cm)"></vxe-column>
            <vxe-column field="meanum" title="平均线性超挖(cm)"></vxe-column>
            <vxe-column field="beizhu" title="备注"></vxe-column>
            <vxe-column field="betonid" width="200" title="操作">
              <template #default="{ row }">
                <el-button size="mini" type="danger" @click="delbeton(row)">删除</el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </template>
      </vxe-modal>
    </div>
    <!--    按范围导出各洞口-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          title="导出各洞口"
          width="50%">
        <el-date-picker
            v-model="dates"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            @change="geturl"
        >
        </el-date-picker>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取消</el-button>
            <el-button size="small" type="primary"><a
                style="text-decoration:none;color: white" :href="url2">导出每日汇总表</a></el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {setNowDate} from "@/utils/time";
import VXETable from "vxe-table";
import {ElMessage} from 'element-plus'
import {mapGetters} from "vuex";

export default {
  inject: ['reload'],
  name: "selfirstshor",
  data() {
    return {
      datesel: '',
      allAlign: 'center',
      tableData: [],
      url: '',
      url2: '',
      opername: '',
      mx: false,
      tableMx: [],
      dialogVisible: false,
      dates: ''
    }
  },
  created() {
    this.datesel = setNowDate(new Date());
    console.log(this.datesel)
    this.url = 'https://www.gjegm.ltd/czsczq/czbeton/leadout?time=' + this.datesel;
    //this.url = 'http://localhost:8082/czbeton/leadout?time=' + this.datesel;
    this.axios.post('/czbeton/getallbeton', (response) => {
      this.tableData = response.obj;
    }, {
      time: this.datesel,
      bdst: '1'
    })
  },
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  methods: {
    selbeton() {
      this.axios.post('/czbeton/getallbeton', (response) => {
        this.tableData = response.obj;
      }, {
        time: this.datesel,
        bdst: '1'
      })
    },
    getSelectionEvent(row) {
      console.log(row)
      this.opername = row.opername;
      this.mx = true;
      this.axios.post('/czbeton/getmxbeton', (response) => {
        console.log(response.obj)
        this.tableMx = response.obj;
      }, {
        opername: row.opername,
        time: this.datesel,
      })
    },
    mergeRowMethod({row, _rowIndex, column, visibleData}) {
      const fields = ['betonid']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return {rowspan: 0, colspan: 0}
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return {rowspan: countRowspan, colspan: 1}
          }
        }
      }
    },
    delbeton(row) {
      this.axios.post('/czbeton/delbeton', (response) => {
        if (response.obj) {
          this.reload();
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
        }
      }, {
        id: row.betonid
      })
    },
    changeurl(val) {

      this.url = 'https://www.gjegm.ltd/czsczq/czbeton/leadout?time=' + val;
          //this.url = 'http://182.92.228.212:8082/czbeton/leadout?time=' + val;
    },
    test() {
      this.axios.post('/czbeton/leadoutow', (response) => {
        ElMessage({
          message: '导入成功',
          type: 'success',
        })
      }, {
        time: this.datesel,
      })
    },
    leadout() {
      this.dialogVisible = true;
    },
    geturl(){
      this.url2 = 'https://www.gjegm.ltd/czsczq/czbeton/leadoutsan?times=' + this.dates
      //this.url2 = 'http://localhost:8082/czbeton/leadoutsan?times=' + this.dates
    }
  }
}
</script>

<style scoped>

</style>